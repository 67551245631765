<template>
  <van-swipe :autoplay="3000" lazy-render>
    <van-swipe-item v-for="image in banners" :key="image">
      <img class="banner-img" :src="image.url" />
    </van-swipe-item>
  </van-swipe>
  <div class="brand-news">
    <div class="en">
      ABOUT
      {{
        companyInfo.companyEnglishName
          ? companyInfo.companyEnglishName
          : "COMPANY"
      }}
    </div>
    <div class="ch">关于{{ companyInfo.companyName }}</div>
  </div>
  <div class="company-introd">
    <div class="img-video-wrap">
      <img
        v-if="aboutCompany[0] && aboutCompany[0].type === 'img'"
        :src="aboutCompany[0].url"
      />
      <video
        v-if="aboutCompany[0] && aboutCompany[0].type === 'video'"
        :src="aboutCompany[0].url"
        :poster="require('@/assets/img/公司优势.jpg')"
        controls="controls"
      >
        您的浏览器不支持 video 标签。
      </video>
    </div>
    <div class="company-describe" v-html="companyInfo.introduce"></div>
  </div>
  <div v-if="brandList && brandList.length > 1" class="brand-news">
    <div class="en">BRAND</div>
    <div class="ch">旗下品牌</div>
  </div>
  <div class="content-wrap">
    <ul class="brand-icon-wrap" v-if="brandList && brandList.length > 1">
      <li
        v-for="(item, index) in brandList"
        :key="index"
        :class="activeIndex === index ? 'active' : 'not-active'"
        @click="changeBrand(item, index)"
      >
        <img :src="item.logos && item.logos[0] && item.logos[0].url" />
      </li>
    </ul>
    <p v-if="currentBrand" class="company-title">
      {{ currentBrand.brandName }}
    </p>
    <p
      v-if="currentBrand"
      class="company-describe"
      v-html="currentBrand.introduce"
    ></p>
    <van-swipe class="brand-banner" :autoplay="3000" lazy-render>
      <van-swipe-item v-for="item in brandBanners" :key="item">
        <video
          v-if="item.type === 'video'"
          :src="item.url"
          :poster="require('@/assets/img/公司优势.jpg')"
          controls
        >
          您的浏览器不支持 video 标签。
        </video>
        <img v-else :src="item.url" />
        <i
          v-if="item.type === 'vr'"
          @click="handleBrandImgClick(item)"
          class="icon vr vr-animation-forever"
        ></i>
      </van-swipe-item>
    </van-swipe>
    <div v-if="joinAdvantage && joinAdvantage.url" class="brand-news">
      <div class="en">FRANCHISE POLICY</div>
      <div class="ch">加盟政策</div>
    </div>
    <div v-if="joinAdvantage && joinAdvantage.url" class="join-avantage-wrap">
      <img :src="joinAdvantage && joinAdvantage.url" />
    </div>
    <div
      v-if="
        companyInfo.shops && companyInfo.shops[0] && companyInfo.shops[0].url
      "
      class="select-store"
    >
      <div class="brand-news" style="margintop: 0">
        <div class="en">SELECT HOTEL</div>
        <div class="ch">精选门店</div>
      </div>
      <ul
        v-if="
          companyInfo.shops && companyInfo.shops[0] && companyInfo.shops[0].url
        "
      >
        <li>
          <img
            :src="
              companyInfo.shops &&
              companyInfo.shops[0] &&
              companyInfo.shops[0].url
            "
          />
        </li>
      </ul>
    </div>
    <div class="form-wrap">
      <div class="brand-news">
        <div class="en" style="color: rgba(255, 255, 255, 0.3)">JOIN-NOW</div>
        <div class="ch" style="color: #fff">加盟邀约</div>
      </div>
      <van-form>
        <van-cell-group inset>
          <van-field
            v-model="ruleForm.area"
            readonly
            name="picker"
            is-link
            label="地区"
            placeholder="点击选择地区"
            @click="showPicker = true"
          />
          <van-field
            v-model="ruleForm.address"
            label="详细地址"
            placeholder="请填写"
          />
          <van-field
            v-model="ruleForm.statesName"
            readonly
            is-link
            name="picker"
            label="项目状态"
            placeholder="请选择"
            @click="showState = true"
          />
          <van-field
            v-model="ruleForm.brandName"
            is-link
            readonly
            name="picker"
            label="期望合作品牌"
            placeholder="请选择"
            @click="showBrands = true"
          />
          <van-field
            v-model="ruleForm.contactsName"
            label="联系人"
            placeholder="请填写"
          />
          <van-field
            v-model="ruleForm.contactsMobile"
            label="手机号"
            placeholder="请填写"
          />
        </van-cell-group>
        <div class="btn-wrap">
          <van-button
            type="primary"
            @click="submit"
            style="marginright: 30rem"
            size="small"
            >提交</van-button
          >
          <van-button type="warning" @click="reset" size="small"
            >重置</van-button
          >
        </div>
      </van-form>
    </div>
  </div>
  <div class="footer">
    <div class="cover-img">
      <div
        v-if="
          companyInfo.otherQrCodes &&
          companyInfo.otherQrCodes[0] &&
          companyInfo.otherQrCodes[0].url
        "
        class="code-box"
      >
        <p>
          {{
            companyInfo.otherQrCodes &&
            companyInfo.otherQrCodes[0] &&
            companyInfo.otherQrCodes[0].name.split(".")[0]
          }}
        </p>
        <img
          :src="
            companyInfo.otherQrCodes &&
            companyInfo.otherQrCodes[0] &&
            companyInfo.otherQrCodes[0].url
          "
        />
      </div>
      <div
        v-if="
          companyInfo.officialAccountQrCodes &&
          companyInfo.officialAccountQrCodes[0] &&
          companyInfo.officialAccountQrCodes[0].url
        "
        class="code-box"
      >
        <p>
          {{
            companyInfo.officialAccountQrCodes &&
            companyInfo.officialAccountQrCodes[0] &&
            companyInfo.officialAccountQrCodes[0].name.split(".")[0]
          }}
        </p>
        <img
          :src="
            companyInfo.officialAccountQrCodes &&
            companyInfo.officialAccountQrCodes[0] &&
            companyInfo.officialAccountQrCodes[0].url
          "
        />
      </div>
      <div class="contact-method">
        <div v-if="companyInfo.joinPhone" class="phone-wrap">
          <img src="../assets/img/phone.png" alt="" />
          <div>加盟热线：{{ companyInfo.joinPhone }}</div>
        </div>
        <div v-if="companyInfo.email" class="email-wrap">
          <img src="../assets/img/email.png" alt="" />
          <div>联系邮箱：{{ companyInfo.email }}</div>
        </div>
      </div>
    </div>
    <div class="icon-wrap">
      <img src="../assets/img/品牌矩阵反白.png" />
    </div>
  </div>
  <van-popup v-model:show="showPicker" position="bottom">
    <van-picker
      v-if="updateComplete"
      ref="picker"
      :columns="columns"
      @change="areaChange"
      :columns-field-names="{ text: 'name', value: 'id', children: 'children' }"
      @cancel="showPicker = false"
      @confirm="selectArea"
    />
  </van-popup>
  <van-popup v-model:show="showState" position="bottom">
    <van-picker
      :columns="states"
      :columns-field-names="{ text: 'label', value: 'id' }"
      @cancel="showState = false"
      @confirm="selectProjectState"
    />
  </van-popup>
  <van-popup v-model:show="showBrands" position="bottom">
    <van-picker
      :columns="brands"
      :columns-field-names="{ text: 'brandName', value: 'id' }"
      @cancel="showBrands = false"
      @confirm="selectBrand"
    />
  </van-popup>
</template>

<script setup>
import {
  getCompanyDetail,
  getProvince,
  getBrand,
  getCity,
  getArea,
  addProject,
} from "@/api/index.js";
import { onMounted, ref, reactive, watch, nextTick } from "vue";
import { useRoute } from "vue-router";
import { Notify } from "vant";
import { trackEvent } from "@/utils/tracklog.js";

const banners = ref([]); // 顶部轮播
const brandBanners = ref([]); // 品牌轮播
const provinces = ref([]);
const brands = ref([]);
const aboutCompany = ref([]);
const companyInfo = ref({});
const brandList = ref([]);
const joinAdvantage = ref({});
const route = useRoute();
const activeIndex = ref(0);
const currentBrand = ref({});
const picker = ref(null);
const ruleForm = reactive({});
const columns = ref([]);
const updateComplete = ref(false);
const showPicker = ref(false);
const showState = ref(false);
const showBrands = ref(false);
const states = [
  { label: "在营", value: "IsStandard_1" },
  { label: "毛坯", value: "IsStandard_2" },
];

onMounted(() => {
  const id = route.params.id;
  Promise.all([
    getDetail(id),
    getProvince().then((res) => {
      provinces.value = res;
      columns.value = res;
      getCitys(provinces.value[0].provinceId);
    }),
    getBrand(Number(id)).then((res) => {
      brands.value = res;
    }),
  ]);
});

watch(
  () => route.params.id,
  async () => {
    aboutCompany.value = [];
    route.params.id && getDetail(route.params.id);
    route.params.id &&
      getBrand(Number(route.params.id)).then((res) => {
        brands.value = res;
      });
    ruleForm.brandName = "";
    ruleForm.brandId = "";
  }
);

const getDetail = (id) => {
  getCompanyDetail(id).then((res) => {
    const { pics, videos, slideShows, brandConfigVoList, joins, companyName } = res;
    videos.forEach((item) => {
      aboutCompany.value.push({ ...item, type: "video" });
    });
    pics.forEach((item) => {
      aboutCompany.value.push({ ...item, type: "img" });
    });
    companyInfo.value = res;
    banners.value = slideShows;
    brandList.value = brandConfigVoList;
    joinAdvantage.value = joins[0];
    currentBrand.value = brandList.value[0]; // 默认第一个品牌
    handleBrandImg(currentBrand.value);
    //埋点 酒管公司
    trackEvent(
      `酒管公司-${companyName}`,
      "浏览",
      `酒管公司-${companyName}`,
      {},
      0,
      new Date().getTime()
    );
  });
};
const handleBrandImg = (brandObj) => {
  brandBanners.value = []; // 先清空轮播
  const { vrPics = [], pics = [], videos = [] } = brandObj ? brandObj : {};
  pics.forEach((item) => {
    brandBanners.value.push({ ...item, type: "img" });
  });
  videos.forEach((item) => {
    brandBanners.value.push({ ...item, type: "video" });
  });
  vrPics.forEach((item) => {
    brandBanners.value.push({ ...item, type: "vr" });
  });
};
const handleBrandImgClick = (item) => {
  if (item.type === "vr") {
    window.open(item.vrHref);
  }
};
const changeBrand = (item, index) => {
  activeIndex.value = index;
  currentBrand.value = item; // 默认第一个品牌
  handleBrandImg(currentBrand.value);
};

const selectProjectState = ({ value, label }) => {
  ruleForm.currentSituation = value;
  ruleForm.statesName = label;
  showState.value = false;
};

const selectBrand = ({ brandName, id }) => {
  ruleForm.brandName = brandName;
  ruleForm.brandId = id;
  showBrands.value = false;
};

const areaChange = (values, index) => {
  if (index === 2) {
    return;
  } // 选择区域
  const [provinceIndex, cityIndex] = picker.value.getIndexes();
  console.log(provinceIndex, cityIndex, "provinceIndex, cityIndex");
  // updateComplete.value = false;
  if (index === 1) {
    // 选择城市
    getAreas(values[1].cityId, provinceIndex, cityIndex);
    picker.value.setColumnIndex(1, cityIndex);
  }
  if (index === 0) {
    // 选择省份
    getCitys(values[0].provinceId, provinceIndex, cityIndex);
    picker.value.setColumnIndex(0, provinceIndex);
  }
};

const selectArea = (values) => {
  const [province, city, district] = values;
  ruleForm.area = `${province.name}/${city.name}/${district.name}`;
  ruleForm.provinceName = province.name;
  ruleForm.cityName = city.name;
  ruleForm.districtName = district.name;
  ruleForm.provinceId = province.provinceId;
  ruleForm.cityId = city.cityId;
  ruleForm.districtId = district.districtId;
  showPicker.value = false;
};

const getCitys = (id, provinceIndex = 0) => {
  getCity(id).then((data) => {
    columns.value[provinceIndex].children = data.map((item) => {
      return {
        ...item,
        name: item.cityName,
        id: item.cityId,
      };
    });
    getAreas(data[0].cityId, provinceIndex, 0, true);
  });
};

const getAreas = (id, provinceIndex = 0, cityIndex = 0, setCity = false) => {
  getArea(id).then(async (data) => {
    columns.value[provinceIndex].children[cityIndex].children = data.map(
      (item) => {
        return {
          ...item,
          id: item.districtId,
        };
      }
    );
    updateComplete.value = true;
    await nextTick();
    if (showPicker.value) {
      setCity &&
        picker.value.setColumnValues(1, columns.value[provinceIndex].children);
      picker.value.setColumnValues(
        2,
        columns.value[provinceIndex].children[cityIndex].children
      );
    }
  });
};

const reset = () => {
  console.log("重置", 123);
  ruleForm.area = "";
  ruleForm.provinceName = "";
  ruleForm.cityName = "";
  ruleForm.districtName = "";
  ruleForm.provinceId = "";
  ruleForm.cityId = "";
  ruleForm.districtId = "";
  ruleForm.currentSituation = "";
  ruleForm.statesName = "";
  ruleForm.brandId = "";
  ruleForm.brandName = "";
  ruleForm.address = "";
  ruleForm.contactsName = "";
  ruleForm.contactsMobile = "";
};

const submit = () => {
  const params = JSON.parse(JSON.stringify(ruleForm));
  delete params.area;
  delete params.statesName;
  delete params.brandName;
  addProject({ ...params, companyId: route.params.id }).then(() => {
    Notify({ type: "success", message: "提交成功" });
  });
};
</script>

<style lang="scss" scoped>
.banner-img {
  width: 100%;
}
.brand-news {
  position: relative;
  margin: 30rem auto 50rem;
  width: fit-content;
  .en {
    font-size: $font-size-large-x;
    color: #eee;
  }
  .ch {
    font-size: $font-size-large-x;
    position: absolute;
    top: 46%;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
  }
}
.company-introd {
  img,
  video {
    width: 750rem;
  }
  .company-describe {
    padding: 20rem;
    line-height: 40rem;
  }
}
.brand-icon-wrap {
  overflow-x: scroll;
  white-space: nowrap;

  li {
    width: 185rem !important;
    height: 185rem !important;
    margin-right: 15rem;
    display: inline-block;
    border-radius: 20rem;
    &.active {
      // transform: scale(1.20);
      border: 1rem solid $color-text-d;
      padding: 0 20rem;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.company-title {
  font-size: $font-size-large-x;
  font-weight: 600rem;
  margin: 20rem 0 12rem 30rem;
}
.company-describe {
  font-size: $font-size-medium-x;
  margin-bottom: 30rem;
  line-height: 40rem;
  color: $color-text-ll;
  padding: 0 30rem;
}
.brand-banner {
  width: calc(100% - 60rem);
  margin: 0 auto;
  img,
  video {
    width: 100%;
  }
}
.join-avantage-wrap {
  margin-bottom: 0px;
  width: 100%;
  font-size: 0;
  img {
    width: 100%;
  }
}
.select-store {
  display: block;
  background: url("../assets/img/精选门店底图.png") no-repeat;
  background-size: 100% 100%;
  //   padding-bottom: 44%;
  //   height: 0;
  ul {
    width: calc(100% - 100rem);
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    li {
      width: 100%;
      //   height: 0;
      //   padding-bottom: 25%;
      background: #eee;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}
.form-wrap {
  padding: 20rem 0;
  background: url("../assets/img/加盟邀约.png") no-repeat;
  background-size: 100% 100%;
  .btn-wrap {
    text-align: center;
    margin: 20rem auto;
  }
}
.footer {
  background: #005250;
  padding: 20rem;
  overflow: hidden;
  height: auto;
  .cover-img {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    padding-top: 40rem;
    color: #fff;
    font-size: 12rem;
    .code-box {
      margin-right: 72rem;
      p {
        text-align: center;
        margin-bottom: 15rem;
        text-align: center;
        line-height: 1.5;
      }
      img {
        width: 100rem;
        height: 100rem;
        display: block;
        margin: 0 auto;
      }
    }
    .contact-method {
      margin-top: 25rem;
      img {
        width: 40rem;
        height: 40rem;
        margin-right: 30rem;
      }
      .phone-wrap {
        margin-bottom: 20rem;
      }
      .phone-wrap,
      .email-wrap {
        display: flex;
        align-items: center;
        line-height: 1.5;
      }
    }
  }
  .icon-wrap {
    img {
      max-width: 100%;
      width: 100%;
    }
  }
}
</style>
<style>
.image-wrap {
  max-width: 100%;
}
</style>